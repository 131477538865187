import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import sal from "sal.js";
import backGif from "../../static/constructions.gif";
import * as styles from "./layout.module.css";

import Header from "./header";
import Footer from "./footer";

const gif = {
  backgroundImage: "url(" + backGif + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  width: "100%",
  height: "0",
  paddingTop: "101.699%", //{img-height / img-width * 100}
};

const gifHolder = {
  maxWidth: "600px",
  margin: "auto",
  maxHeight: "600px",
};

const Layout = ({ children, menus }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Initialize scroll animations
  useEffect(() => {
    sal();
  }, []);

  // // // // //
  // Original //
  // // // // //

  // return (
  //     <div className="wrapper overflow-hidden">
  //         <h1 className="hidden">{ data.site.siteMetadata.title }</h1>
  //         <Header menus={ menus }/>
  //         <div>
  //             { children }
  //         </div>
  //         <Footer menus={ menus }/>
  //     </div>
  // );

  //  // // // // //
  // Construction //
  //  // // // // //

  return (
    <section
      style={{
        backgroundColor: "#4b52db",
        textAlign: "center",
        height: "100vh",
      }}
    >
      <h1>YESHi</h1>
      <article style={gifHolder}>
        <div style={gif}></div>
      </article>
      <h2>You caught us while we're updating our site.</h2>
    </section>
  );
};

Layout.defaultProps = {
  menus: null,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menus: PropTypes.any,
};

export default Layout;
